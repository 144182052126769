import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { toast } from "react-semantic-toasts";
import { Button, Container, Dimmer, Form, Grid, Header, Icon, Label, Loader, Segment, Table } from "semantic-ui-react";
import Can from "../../../abilities/Can";
import OfferRequestForm from "../../../components/core/offer-request/OfferRequestForm";
import { startGetActivityTypes } from "../../../store/actions/core/administration/activity_type";
import { startGetInvoiceTypes } from "../../../store/actions/core/administration/invoice_type";
import { startGetOfferingTypes } from "../../../store/actions/core/administration/offering_type";
import { startGetProductTypes } from "../../../store/actions/core/administration/product_type";
import { startGetProjectTypes } from "../../../store/actions/core/administration/project_type";
import { startGetCustomers } from "../../../store/actions/core/customer-creation/customer";
import { startCreateOfferRequest } from "../../../store/actions/core/offer-request/offer_request";
import { startGetProject_manager } from "../../../store/actions/core/project/project_manager";
import { startGetCompanies } from "../../../store/actions/shared/company";
import { startGetCurrencies } from "../../../store/actions/shared/currency";
import { startGetSalesLevels } from "../../../store/actions/shared/sales_level";
import constants from "../../../utils/constants";
import { startGetUseApprovers } from '../../../store/actions/core/offer-request/expenses-approver/use_approvers';
import { startGetExpensesApprover } from '../../../store/actions/core/offer-request/expenses-approver/expenses_approver';


class OfferRequestContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      preview: false,
      step: 1,
      values: {},
      user_info: this.props.user_info,
      getCustomers: this.getCustomers,
      error: "",
      project_options: [],
      offering_options: [],
      invoice_options: [],
      product_options: [],
      salesLevels: [],
      invoicing_condition: "",
      automatic_renewal: false,
      rate_error: "",
      decommission_date: null,
      has_decommission_date: 0,
      is_decommission_date_disabled: false,
      show_use_expenses_approver: false
    };

  }

  componentDidMount() {
    // Load data needed for dropdown selections
    this.props.getInvoiceTypes();
    this.props.getActivityTypes();
    this.props.getProductTypes();
    this.props.getCurrencies();
    this.props.getCompanies();
    this.props.getProjectManager();
    this.props.getOfferingTypes();
    this.props.getProjectTypes();
    this.props.getCustomers();
    this.props.getUseApprovers();
    this.props.getExpensesApprover();
    this.checkPropsAndUpdateState();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user_info !== prevState.user_info) {
      return {
        user: nextProps.user_info,
      };
    }
    else return null;
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user_info !== this.props.user_info && !this.state.values.hasOwnProperty("company_type_text") && this.state.step === 1) {
      //Perform some operation here
      let vals = { ...this.state.values };
      vals.currency_type = this.props.user_info.signInUserSession && JSON.parse(this.props.user_info.signInUserSession.idToken.payload.company_details).currency;
      vals.company_type = this.props.user_info.signInUserSession && JSON.parse(this.props.user_info.signInUserSession.idToken.payload.company_details).name;
      this.setState({
        user_info: this.props.user_info,
        values: vals
      });
    }
    if (prevProps.use_hours_expenses_approver !== this.props.use_hours_expenses_approver || prevProps.company_id !== this.props.company_id) {
      this.checkPropsAndUpdateState();
  }
  }

  checkPropsAndUpdateState = () => {
    let shouldShow = this.props.use_hours_expenses_approver === 1 && this.props.company_id === this.props.authCompany_id;
    this.setState({ show_use_expenses_approver: shouldShow });
  };


  addSalesLevel = () => {
    console.log("CURRENCY ", this.state.values.currency_type);
    let salesLevels = this.state.salesLevels;
    if (salesLevels.length > 0) {
      salesLevels[salesLevels.length - 1].blocked = true;
      _.remove(this.props.salesLevels, function (o) {
        return o.key === _.last(salesLevels).level;
      });
    }
    salesLevels.push({ level: "", rate: 0, name: "", role: "", block_hours: 0, blocking: false, error: "" });
    this.setState({
      salesLevels: salesLevels
    });
  };

  removeSaleLevel = (i) => {
    let salesLevels = this.state.salesLevels;
    if (salesLevels[i].blocked) {
      this.props.salesLevels.push({ key: salesLevels[i].level, value: salesLevels[i].name, text: salesLevels[i].name, sl_enabled: 1 });
    }
    salesLevels.splice(i, 1);
    this.setState({
      salesLevels: salesLevels
    });
  };

  onChangeSaleLevel = (e, data, index) => {
    let salesLevels = this.state.salesLevels;
    let sl = salesLevels[index];
    let level_id = data.options.find(o => o.value === data.value).key;
    sl["level"] = level_id;
    sl["name"] = data.value;
    this.setState({
      salesLevels: salesLevels
    });

  };


  onChangeRate = (e, value, index) => {
    let salesLevels = this.state.salesLevels;
    let sl = salesLevels[index];
    sl["rate"] = value;
    if (parseInt(value, 10) >= 0) {
      sl["error"] = "";
    } else {
      sl["error"] = " Rate not valid!"
    }
    this.setState({
      salesLevels: salesLevels
    });

  };

  onChangeSOWRole = (e, value, index) => {
    let salesLevels = this.state.salesLevels;
    let sl = salesLevels[index];
    sl["role"] = value;
    this.setState({
      salesLevels: salesLevels
    });
  }

  onChangeBlockHour = (e, value, index) => {
    let salesLevels = this.state.salesLevels;
    let sl = salesLevels[index];
    sl["block_hours"] = value;
    if (parseInt(value, 10) >= 0) {
      sl["error"] = "";
    } else {
      sl["error"] = " Hours not valid!"
    }
    this.setState({
      salesLevels: salesLevels
    });

  };

  onChangeBlocking = (e, data, index) => {
    let salesLevels = this.state.salesLevels;
    let sl = salesLevels[index];
    sl["blocking"] = data.checked;
    this.setState({
      salesLevels: salesLevels
    });
  };

  onCancel = () => {
    this.setState({
      values: {
        currency_type: this.props.user_info.signInUserSession && JSON.parse(this.props.user_info.signInUserSession.idToken.payload.company_details).currency,
        company_type: this.props.user_info.signInUserSession && JSON.parse(this.props.user_info.signInUserSession.idToken.payload.company_details).name
      },
      preview: false,
      user_info: this.props.user_info,
      step: 1,
      error: "",
      project_options: [],
      offering_options: [],
      invoice_options: [],
      product_options: [],
      salesLevels: [],
      invoicing_condition: "",
      automatic_renewal: false,
      rate_error: "",
      has_decommission_date: 0,
      decommission_date: null
    });
  };


  handleChange = (e, data) => {
    const re = /^[a-zA-Z0-9]{0,5}$/;

    if (data.name === "prj_suffix") {
      if (!re.test(String(data.value).toLowerCase())) {
        this.setState({
          error: "The project suffix is not valid!"
        })
      } else {
        this.setState({
          error: ""
        })
      }
    }

    if (data.name === "activity_type") {
      let result_project = [];
      if (data.value === "IN") {
        result_project = this.props.options.project_type.filter(function (obj) {
          return obj.value === "INV"
        })
      } else if (data.value === "SV") {
        result_project = this.props.options.project_type.filter(function (obj) {
          return obj.value !== "INV"
        })
      } else if (data.value === "PD") {
        result_project = this.props.options.project_type.filter(function (obj) {
          return obj.value !== "INV" && obj.value !== "TS"
        })
      } else {
        result_project = this.props.options.project_type;
      }
      let result_offering = this.props.options.offering_type.filter(function (obj) {
        return obj.activity_type === data.value;
      });
      this.setState({
        offering_options: result_offering,
        project_options: result_project
      });
    }

    if (data.name === "project_type") {
      let result_invoice = [];
      if (data.value === "INV") {
        result_invoice = this.props.options.invoice_type.filter(function (obj) {
          return obj.value === "INV" || obj.value === "SA"
        })
      } else if (data.value === "SA" && this.state.values.activity_type === "SV") {
        result_invoice = this.props.options.invoice_type.filter(function (obj) {
          return obj.value !== "INV"
        })
      } else if ((data.value === "SA" || data.value === "CM") && this.state.values.activity_type === "PD") {
        result_invoice = this.props.options.invoice_type.filter(function (obj) {
          return obj.value !== "TS"
        })
      } else if (data.value === "TS") {
        result_invoice = this.props.options.invoice_type.filter(function (obj) {
          return obj.value === "TS"
        })
      } else if (data.value === "CM" && this.state.values.activity_type === "SV") {
        result_invoice = this.props.options.invoice_type.filter(function (obj) {
          return obj.value === "CM"
        })
      } else {
        result_invoice = this.props.options.invoice_type;
      }
      this.setState({
        invoice_options: result_invoice
      });
    }

    if (data.name === "offering_type") {
      let result = _.filter(this.props.options.product_type, { 'offering_type': data.value, 'company_id': this.state.values.company_type_id })
      let has_decommission_date = 0
      this.props.options.offering_type.map(o => {
        if (o.value === data.value) {
          has_decommission_date = o.has_decommission_date
        }
        return null;
      })
      this.setState({
        product_options: result,
        has_decommission_date: has_decommission_date
      });
    }
    let values = {};
    if (data.options) {
      let data_id = data.options.find(o => o.value === data.value).key;
      let data_text = data.options.find(o => o.value === data.value).text;
      let name_id = data.name + "_id";
      let name_text = data.name + "_text";
      values = {
        ...this.state.values,
        [name_id]: data_id,
        [data.name]: data.value,
        [name_text]: data_text
      };
      if (data.name === 'hours_expenses_approver') {
        values.approver_unique_id = data_id;
      }
    } else {
      values = {
        ...this.state.values,
        [data.name]: data.value
      };
    }
    if (!this.state.values.hasOwnProperty("company_type_id") && this.state.values.hasOwnProperty("company_type")) {
      let company_type_id = this.props.options.company_type.find(o => o.value === this.state.values.company_type).key;
      values.company_type_id = company_type_id;
    }

    if (!this.state.values.hasOwnProperty("currency_type_id") && this.state.values.hasOwnProperty("currency_type")) {
      let value_to_search = this.state.values.currency_type
      if (data.name === "currency_type") {
        value_to_search = data.value
      }
      let currency_type_id = this.props.options.currency_type.find(o => o.value === value_to_search).key;
      values.currency_type_id = currency_type_id;
    }

    this.setState({
      values: values
    });
  };


  handleChangeCompany = (e, data) => {
    let vals = Object.assign({}, this.state.values);
    if (vals.hasOwnProperty("offering_type") && data.value !== vals.company_type) {
      let result = _.filter(this.props.options.product_type, { 'offering_type': vals.offering_type, 'company': data.value })
      this.setState({
        product_options: result
      });
    }
    let data_id = data.options.find(o => o.value === data.value).key;
    let data_text = data.options.find(o => o.value === data.value).text;
    vals.company_type_id = data_id;
    vals.company_type = data.value;
    vals.company_type_text = data_text;
    this.setState({
      values: vals
    });
  }

  handleBack = () => {
    switch (this.state.step) {
      case 2:
        this.setState({
          preview: false,
          step: 1
        });
        break;
      case 3:
        if (this.state.values.activity_type === "PD") {
          this.setState({
            preview: false,
            step: 4
          });
        } else if (this.state.values.project_type === "TS") {
          this.setState({
            preview: false,
            step: 2
          });
        } else {
          this.setState({
            preview: false,
            step: 1
          });
        }
        break;
      case 4:
        if (this.state.values.project_type === "TS") {
          this.setState({
            preview: false,
            step: 2
          });
        } else {
          this.setState({
            preview: false,
            step: 1
          });
        }
        break;
      default:
        this.setState({
          preview: false,
          step: 1
        });
    }

  };


  handleSubmit = () => {
    const onFailure = res => {
      toast(
        {
          title: "Offer Request",
          description: "Error creating offer request. Try Again",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );
      this.onCancel();
    };

    const onSuccess = res => {
      toast(
        {
          title: "Offer Request",
          description: "Offer request created successfully and waiting for approval.",
          type: "success",
          icon: "check",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        }
      );
      this.onCancel();
    };
    let vals = Object.assign({}, this.state.values);
    let unnecessaryFields = ["activity_type", "activity_type_text", "final_client_type", "final_client_type_text", "billing_client_type", "billing_client_type_text", "project_manager", "project_manager_text", "company_type", "company_type_text", "currency_type_text", "offering_type", "offering_type_text", "project_type", "project_type_text", "invoice_type", "invoice_type_text", "product_type", "product_type_text", "hours_expenses_approver", "hours_expenses_approver_id", "hours_expenses_approver_text"];
    for (let i = 0; i < unnecessaryFields.length; i++) {
      let elem = unnecessaryFields[i];
      if (vals.hasOwnProperty(elem)) delete vals[elem];
    }
    let newDecommissionDate = null
    if (this.state.decommission_date) {
      //newDecommissionDate=this.state.decommission_date.format("YYYY-MM-DD")
      newDecommissionDate = this.state.decommission_date
    }
    vals["decommission_date"] = newDecommissionDate;
    this.props.createOfferRequest(vals, onSuccess, onFailure);
  };

  handleNext = () => {
    let payload = Object.assign({}, this.state.values);
    switch (this.state.step) {
      case 1:
        // TS: time material, PD: product
        if (this.state.values.project_type === "TS" && this.state.values.activity_type !== "PD") {
          this.props.getSalesLevels();
          if (payload.product_type) delete payload.product_type;
          if (payload.automatic_renewal) delete payload.automatic_renewal;
          if (payload.invoicing_condition) delete payload.invoicing_condition;
          this.setState({
            values: payload,
            step: 2,
            automatic_renewal: false,
            invoicing_condition: ""
          });
        } else if (this.state.values.activity_type === "PD" && this.state.values.project_type !== "TS") {
          this.setState({
            step: 4,
            sales_levels: []
          });
          if (payload.sales_levels) delete payload.sales_levels;
        } else if (this.state.values.activity_type !== "PD" && this.state.values.project_type !== "TS") {
          if (payload.product_type) delete payload.product_type;
          if (payload.automatic_renewal) delete payload.automatic_renewal;
          if (payload.invoicing_condition) delete payload.invoicing_condition;
          if (payload.sales_levels) delete payload.sales_levels;
          this.setState({
            step: 3,
            preview: true,
            sales_levels: [],
            automatic_renewal: false,
            invoicing_condition: "",
            values: payload
          });
        } else {
          this.props.getSalesLevels();
          this.setState({
            step: 2
          });
        }
        break;
      case 2:
        if (this.state.salesLevels.length > 0) {
          let sales_levels = this.state.salesLevels.map(sl => ({ "level": sl.level, "rate": parseInt(sl.rate), "name": sl.name, "role": sl.role, "block_hours": parseInt(sl.block_hours) === 0 ? null : parseInt(sl.block_hours), "blocking": sl.blocking }));
          payload.sales_levels = sales_levels;
          this.setState({
            values: payload
          });
        }
        if (this.state.values.activity_type === "PD") {
          this.setState({
            preview: false,
            step: 4
          });
        } else {
          this.setState({
            preview: true,
            step: 3
          });
        }
        break;
      case 4:
        payload.invoicing_condition = this.state.invoicing_condition;
        payload.automatic_renewal = this.state.automatic_renewal;
        this.setState({
          preview: true,
          step: 3,
          values: payload
        });
        break;
      default:
        break;

    }

    if (!this.state.show_use_expenses_approver) {
      this.setState((prev) => ({...prev, values: {...prev.values, approver_unique_id: prev.values.project_manager_id}}));
    }
  };

  generateExtraSummary = () => {
    let isTimeMaterial = false;
    let isProduct = false;
    if (this.state.values.project_type === "TS") {
      isTimeMaterial = true;
    }
    if (this.state.values.activity_type === "PD") {
      isProduct = true;
    }

    if (!isTimeMaterial && !isProduct) {
      return false;
    }

    let componentProduct = "";
    let componentTimeMaterial = "";

    if (isTimeMaterial)
      componentTimeMaterial = (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Sale Level</Table.HeaderCell>
              <Table.HeaderCell>Rate</Table.HeaderCell>
              <Table.HeaderCell>Currency</Table.HeaderCell>
              <Table.HeaderCell>SOW Role</Table.HeaderCell>
              <Table.HeaderCell>Block Hours</Table.HeaderCell>
              <Table.HeaderCell>Blocking</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.salesLevels.map((sl, i) => (
              <Table.Row key={i}>
                <Table.Cell>{sl.name}</Table.Cell>
                <Table.Cell>{sl.rate}</Table.Cell>
                <Table.Cell>{this.state.values.currency_type}</Table.Cell>
                <Table.Cell>{sl.role}</Table.Cell>
                <Table.Cell>{(sl.block_hours === 0 || sl.block_hours === null) ? "No Block" : sl.block_hours}</Table.Cell>
                <Table.Cell>{sl.blocking ? "Yes" : "No"}</Table.Cell>
              </Table.Row>
            )
            )}
          </Table.Body>
        </Table>
      );

    if (isProduct)
      componentProduct = (
        <Table celled>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Invoicing condition</Table.Cell>
              <Table.Cell>{this.state.invoicing_condition}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Automatic Renewal</Table.Cell>
              <Table.Cell>{this.state.automatic_renewal ? "Yes" : "No"}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      );

    return (
      <div>
        {componentTimeMaterial}
        {componentProduct}
      </div>
    );
  };

  /**
   * Change the termination date of product
   */
  onDecommissionDateChange = (date) => {
    let newDate = null;
    if (date) {
      newDate = moment(date).format("YYYY-MM-DD")
    }
    this.setState({
      decommission_date: newDate
    });
  };

  toggleDecommissionDateDisabled = () => {
    this.setState({ is_decommission_date_disabled: !this.state.is_decommission_date_disabled });
  }

  render() {

    const loading = this.props.loadingOfferRequest;
    const initial_loading = this.props.loadings.activity_type;

    return (
      <div>
        <Dimmer active={loading || initial_loading} inverted >
          <Loader indeterminate inverted content="Loading" size="medium" />
        </Dimmer>
        <Container>
          <Header>Offer Request</Header>
          <Can I='offer_request:Read' a='offer-request-admin'>
            {(this.state.step === 1 || this.state.step === 3) &&
              <Segment clearing>
                <OfferRequestForm
                  values={this.state.values}
                  handleChange={this.handleChange}
                  handleChangeCompany={this.handleChangeCompany}
                  onSubmit={this.handleSubmit}
                  loadings={this.props.loadings}
                  options={this.props.options}
                  summary={this.state.preview}
                  onCancel={this.onCancel}
                  onNext={this.handleNext}
                  onBack={this.handleBack}
                  extra={this.generateExtraSummary()}
                  project_options={this.state.project_options}
                  offering_options={this.state.offering_options}
                  invoice_options={this.state.invoice_options}
                  product_options={this.state.product_options}
                  error={this.state.error}
                  has_decommission_date={this.state.has_decommission_date}
                  decommission_date={this.state.decommission_date}
                  onDecommissionDateChange={this.onDecommissionDateChange}
                  is_decommission_date_disabled={this.state.is_decommission_date_disabled}
                  toggleDecommissionDateDisabled={this.toggleDecommissionDateDisabled}
                  showUseExpensesApprover={this.state.show_use_expenses_approver}
                />
              </Segment>
            }
            {this.state.step === 2 &&
              <Segment clearing>
                <Form>
                  {/*<Form ref={this.salesLevelRef}>*/}
                  <h3>Insert the Sales Level and the corresponding daily rate for each professional figure</h3>
                  <Button onClick={this.addSalesLevel}><Icon name='add' />Add</Button>

                  <Grid columns={6} stackable>
                    <Grid.Row>
                      <Grid.Column width={4}>
                        <Label className="label-icon" style={{ "marginLeft": "10px", "display": "inline-block" }}>Sales Level</Label>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Label className="label-icon" style={{ "marginLeft": "10px", "display": "inline-block" }}>Daily Rate</Label>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Label className="label-icon" style={{ "marginLeft": "10px", "display": "inline-block" }}>Currency</Label>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Label className="label-icon" style={{ "marginLeft": "10px", "display": "inline-block" }}>SOW Role</Label>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Label className="label-icon" style={{ "marginLeft": "10px", "display": "inline-block" }}>Block Hours</Label>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Label className="label-icon" style={{ "display": "inline-block" }}></Label>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  {this.state.salesLevels.map((sl, i) => (
                    <Form.Group key={i}>
                      {!sl.blocked ?
                        <Form.Select
                          value={sl.name}
                          error={false}
                          placeholder={"Select one Sales Level"}
                          loading={this.props.loadingSalesLevels}
                          options={this.props.salesLevels}
                          onChange={(e, data) =>
                            this.onChangeSaleLevel(e, data, i)}
                          width={4}
                        />
                        :
                        <Form.Input
                          value={sl.name}
                          width={4}
                          readOnly={true}
                        />}
                      <Form.Input
                        placeholder='Daily rate'
                        value={sl.rate}
                        type='number'
                        error={sl.error !== ""}
                        onChange={(e, { value }) =>
                          this.onChangeRate(e, value, i)}
                        width={2}
                      />
                      <Form.Input
                        placeholder='Currency'
                        value={this.state.values.currency_type || ""}
                        readOnly
                        width={2}
                      />
                      <Form.Input
                        placeholder='SOW Role'
                        value={sl.role || ""}
                        onChange={(e, { value }) =>
                          this.onChangeSOWRole(e, value, i)}
                        width={2}
                      />
                      <Form.Input
                        placeholder='Block Hours'
                        value={sl.block_hours}
                        type='number'
                        error={sl.error !== ""}
                        onChange={(e, { value }) =>
                          this.onChangeBlockHour(e, value, i)}
                        width={2}
                      />

                      <Form.Checkbox
                        toggle={true}
                        label={'Blocking hours'}
                        checked={sl.blocking || false}
                        style={{ "marginTop": "0.2rem", "border": "0" }}
                        onChange={(e, data) =>
                          this.onChangeBlocking(e, data, i)}
                        width={3}
                      />

                      <Button color='red' onClick={() => this.removeSaleLevel(i)} width={2}>Remove</Button>
                      <div>
                        <br />
                        <p style={{ "textAlign": "right", "color": "red" }}>{sl.error}</p>
                      </div>
                    </Form.Group>
                  )
                  )
                  }
                  <div>
                    <Button className="grey"
                      floated='left'
                      onClick={this.handleBack}>
                      Back
                    </Button>
                    <Button floated='right'
                      onClick={this.handleNext}
                      disabled={_.isEmpty(this.state.salesLevels) || Object.values(this.state.salesLevels).some(x => (x.error !== "")) || Object.values(this.state.salesLevels).some(x => (x.level === ""))}>
                      Next
                    </Button>
                  </div>
                </Form>
              </Segment>
            }
            {this.state.step === 4 &&
              <Segment clearing>
                <Form>
                  <Form.Select
                    required
                    label='Invoicing condition'
                    placeholder='Select one...'
                    options={[
                      {
                        "key": "one shot",
                        "value": "One shot",
                        "text": "One Shot"
                      },
                      {
                        "key": "monthly",
                        "value": "Monthly",
                        "text": "Monthly"
                      },
                      {
                        "key": "quarterly",
                        "value": "Quarterly",
                        "text": "Quarterly"
                      }

                    ]}
                    value={this.state.invoicing_condition}
                    onChange={(e, { value }) => (this.setState({ invoicing_condition: value }))}
                  />
                  <Form.Checkbox
                    toggle
                    label='Automatic renewal'
                    checked={this.state.automatic_renewal}
                    onChange={(e, { checked }) => (this.setState({ automatic_renewal: checked }))} />
                  <div>
                    <Button className="grey"
                      floated='left'
                      onClick={this.handleBack}>
                      Back
                    </Button>
                    <Button floated='right'
                      disabled={this.state.invoicing_condition === ""}
                      onClick={this.handleNext}>
                      Next
                    </Button>
                  </div>
                </Form>
              </Segment>
            }
          </Can>
        </Container>

        <div />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  company_code: state.authReducer.company_code,
  authCompany_id: state.authReducer.user_details && state.authReducer.user_details[0] && state.authReducer.user_details[0].company_id,
  loadingOfferRequest: state.offerRequestReducer.loading,
  loadingSalesLevels: state.salesLevelsReducer.loading,
  use_hours_expenses_approver: state.useApproversReduer.use_hours_expenses_approver,
  company_id: state.useApproversReduer.company_id,
  salesLevels: _.toArray(
    _.mapValues(_.filter(state.salesLevelsReducer.levels, { 'sl_enabled': 1, 'visible': 1, 'publicly_available': 1 }), o => {
      return {
        key: o.id,
        value: o.code,
        text: o.code
      };
    })
  ),
  user_info: _.mapValues(state.authReducer.user),
  options: {
    project_type: _.toArray(
      _.mapValues(_.filter(state.projectTypeReducer.projecttypes, { 'active': 1, 'corporate': 0 }), o => {
        return {
          key: o.id,
          value: o.code,
          text: o.name,
          code: o.code
        };
      })
    ),
    currency_type: _.toArray(
      _.mapValues(state.currencyReducer.currencies, o => {
        return {
          key: o.id,
          value: o.code,
          text: o.code
        };
      })
    ),
    company_type: _.toArray(
      _.mapValues(_.filter(state.companyReducer.companies, { 'active': 1 }), o => {
        return {
          key: o.id,
          value: o.name,
          text: o.name,
          code: o.code
        };
      })
    ),
    offering_type: _.toArray(
      _.mapValues(_.filter(state.offeringTypeReducer.offeringtypes, i => i.code !== "DDMP" && i.code !== "DDMS" && i.code !== "DDMI" && i.active === 1), o => {
        return {
          key: o.id,
          value: o.code,
          text: o.name,
          activity_type: o.activity_type_code,
          has_decommission_date: o.has_decommission_date
        };
      })
    ),
    
    project_manager: _.chain(state.project_managerReducer.project_manager)
      .map(o => {
        const fullName = `${o.name} ${o.surname}`;
        return {
          key: o.unique_id,
          value: fullName,
          text: fullName,
          searchText: fullName.toLowerCase()
        };
      })
      .sortBy('text')
      .value(),

    final_client_type: _.orderBy(_.toArray(
      _.mapValues(_.filter(state.customerReducer.customers, { 'active': 1 }), o => {
        return {
          key: o.id,
          value: o.name,
          text: o.name
        };
      })),
      ['value'],
      ['asc']
    ),
    billing_client_type: _.orderBy(_.toArray(
      _.mapValues(_.filter(state.customerReducer.customers, { 'active': 1 }), o => {
        return {
          key: o.id,
          value: o.name,
          text: o.name
        };
      })),
      ['value'],
      ['asc']
    ),
    activity_type: _.toArray(
      _.mapValues(_.filter(state.activityTypeReducer.activitytypes, { 'active': 1 }), o => {
        return {
          key: o.id,
          value: o.code,
          text: o.name,
          code: o.code
        };
      })
    ),
    product_type: _.toArray(
      _.mapValues(_.filter(state.productTypeReducer.producttypes, { 'active': 1 }), o => {
        return {
          key: o.id,
          value: o.code + " - " + o.name + " (" + o.vendor + ") ",
          text: o.code + " - " + o.name + " (" + o.vendor + ") ",
          offering_type: o.offering_type_code,
          company: o.company,
          company_id: o.company_id
        };
      })
    ),
    invoice_type: _.toArray(
      _.mapValues(_.filter(state.invoiceTypeReducer.invoicetypes, { 'active': 1 }), o => {
        return {
          key: o.id,
          value: o.code,
          text: o.name
        };
      })
    ),
    expenses_approver: state.expensesApproverReducer.hours_expenses_approver
    ? state.expensesApproverReducer.hours_expenses_approver.map(o => ({
        key: o.unique_id,
        value: `${o.name} ${o.surname}`,
        text: `${o.name} ${o.surname}`
      }))
    : []
  },
  loadings: {
    invoice_type: state.invoiceTypeReducer.loading,
    project_type: state.projectTypeReducer.loading,
    offering_type: state.offeringTypeReducer.loading,
    activity_type: state.activityTypeReducer.loading,
    product_type: state.productTypeReducer.loading,
    currency_type: state.currencyReducer.loading,
    company_type: state.companyReducer.loading,
    final_client_type: state.customerReducer.loading,
    billing_client_type: state.customerReducer.loading,
    project_manager: state.project_managerReducer.loading,
    expenses_approver: state.expensesApproverReducer.loading,
  }

});

const mapDispatchToProps = dispatch => {
  return {
    //getCustomers: (company_code) => dispatch(startGetCustomersByCompany(company_code)),
    getCustomers: () => dispatch(startGetCustomers()),
    getInvoiceTypes: () => dispatch(startGetInvoiceTypes()),
    getActivityTypes: () => dispatch(startGetActivityTypes()),
    getOfferingTypes: () => dispatch(startGetOfferingTypes()),
    getProductTypes: () => dispatch(startGetProductTypes()),
    getCurrencies: () => dispatch(startGetCurrencies()),
    getCompanies: () => dispatch(startGetCompanies("OFFER_REQUEST")),
    getSalesLevels: () => dispatch(startGetSalesLevels()),
    getProjectManager: () => dispatch(startGetProject_manager()),
    getProjectTypes: () => dispatch(startGetProjectTypes()),
    getUseApprovers: () => dispatch(startGetUseApprovers()),
    getExpensesApprover: () => dispatch(startGetExpensesApprover()),
    createOfferRequest: (params, onSuccess, onFailure) => dispatch(startCreateOfferRequest(params, onSuccess, onFailure)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferRequestContainer);
